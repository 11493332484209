<template>
  <v-dialog v-model="dialog" width="70%">
    <v-card style="display: flex; flex-direction: column;">
      <v-card-title v-text="user === null ? 'New User' : 'Update User'"/>
      <v-card-text style="overflow-y: scroll;">
        <v-form>
          <v-text-field :disabled="user !== null" label="User Name" v-model="state.username"
                        :rules="[validationRules.email]"/>
          <v-select v-if="state.role" label="Role" v-model="state.role"
                    :items="Object.keys(roles).map(r => {return {text: roles[r], value: r}})"/>
          <v-select v-if="state.role && state.role === 'ROLE_CUSTOMER_USER'" label="Customer"
                    v-model="state.customerId"
                    :items="customerIds"/>
          <v-select v-if="state.role && state.role === 'ROLE_MNO_USER'" label="MNO" v-model="state.mnoId"
                    :items="mnos"/>
          <v-switch v-if="user !== null && unlockLogin" label="Unlock login" @change="handleUnlockLogin"
                    :disabled="disableUnlockLogin"/>
          <v-switch v-if="user !== null && unlock2FA" label="Reset 2FA" @change="handleReset2FA"
                    :disabled="disableReset2FA"/>
          <v-switch v-if="user !== null" label="Reset password" v-model="state.resetPassword"/>
          <v-text-field v-if="user == null || state.resetPassword" label="Password" type="password"
                        v-model="state.password"
                        :rules="[validationRules.password]"/>
          <v-text-field v-if="user == null || state.resetPassword" label="Retype Password" type="password"
                        v-model="state.retypePassword" :rules="[validationRules.passRetype]"/>
          <v-text-field label="Phone number" v-model="state.msisdn" :rules="[validationRules.msisdn]"/>
          <v-btn @click="validateMsisdn">Validate</v-btn>
          <v-text-field label="Device Id:" v-model="deviceIdentifier"/>
          <v-text-field label="Sim Id:" v-model="simIdentifier"/>
        </v-form>
      </v-card-text>
      <v-btn class="card" color="secondary" v-text="user === null ? 'ADD' : 'UPDATE'"
             @click="submit()" style="margin-left: 35% ; max-width: 30%;"/>
    </v-card>
  </v-dialog>
</template>

<script>

import utils from '@/mixins/utils';

export default {
  name: 'UserComponent',
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    },
  },
  watch: {
    user() {
      this.openDialog();
    }
  },
  data() {
    return {
      state: {},
      unlockLogin: false,
      disableUnlockLogin: false,
      unlock2FA: false,
      disableReset2FA: false,
      isPasswordValid: false,
      isRetypePasswordValid: false,
      originalRole: null,
      shouldReload: true,
      deviceIdentifier: null,
      simIdentifier: null,
      customerIds: [],
      mnos: [],
      customerId: null,
      mnoId: null,
      dialog: false,
      validationRules: {
        password: v => (!v || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!()])(?=\S+$).{8,}$/.test(v)) || 'password must be minimum 8 characters, must contain at least one number, at least one capital letter, at least one small letter and at least one special character',
        passRetype: value => (value && value === this.state.password) || 'Passwords are not the same',
        msisdn: v => (!v || /^[0-9]{10,13}$/.test(v)) || 'MSISDN does not meet the required criteria. ' + ' sign not allowed',
        email: v => (!v || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)) || 'Must be a valid email',
      }
    }
  },
  mixins: [utils],
  methods: {
    openDialog() {
      if (this.shouldReload) {
        this.shouldReload = false;
        this.loadData();
      }

      this.dialog = true;
      this.resetPassword = false;
      this.disableUnlockLogin = false;
      this.disableReset2FA = false;
      this.state = this.user === null ? {
        role: 'ROLE_CUSTOMER_USER',
      } : {...this.user};
      // this.originalRole = this.state.role;
    },
    async validateMsisdn() {
      const result = await this.httpPost('api/auth/validateMsisdn', {"msisdn": this.state.msisdn});
      if (result) {
        this.deviceIdentifier = result.data.deviceIdentifier;
        this.simIdentifier = result.data.simIdentifier;
        this.$emit('reload');
      }
    },
    async submit() {
      if (this.user === null || this.resetPassword) {
        if (this.validationRules.password(this.state.password) !== true) {
          alert('Not a valid password');
          return;
        }
        if (this.validationRules.passRetype(this.state.retypePassword) !== true) {
          alert('Passwords are not the same');
          return;
        }
      }

      if (this.user === null) {
        if (!this.state.username) {
          alert('User name must be filled');
          return;
        }
        try {
          this.state.resetPassword = true;
          await this.httpPost('api/user/create', this.state)
          this.$emit('reload');
        } catch (error) {
          alert(error.response.statusText);
        }
      } else {
        try {

          if (this.originalRole !== this.state.role || this.resetPassword) {
            await this.httpPost('api/user/update', this.state)
            Object.assign(this.user, this.state);
          }
        } catch (error) {
          alert(error.response.statusText);
        }
      }

      this.dialog = false;
    },
    async handleUnlockLogin(value) {
      if (value) {
        await this.httpGet('api/user/unlock-login?' + new URLSearchParams({username: this.user.username}));
        this.disableUnlockLogin = true;
        this.shouldReload = true;
      }
    },
    async handleReset2FA(value) {
      if (value) {
        await this.httpGet('api/user/reset-2fa?' + new URLSearchParams({username: this.user.username}));
        this.disableReset2FA = true;
        this.shouldReload = true;
      }
    },
    async loadData() {
      if (this.user !== null) {
        const result1 = await this.httpGet('api/user/is-login-locked?' + new URLSearchParams({username: this.user.username}));
        if (result1) {
          this.unlockLogin = result1.data.locked;
        }

        const result2 = await this.httpGet('api/user/is-2fa-enabled?' + new URLSearchParams({username: this.user.username}));
        if (result2) {
          this.unlock2FA = result2.data.enabled;
        }
      }
      //call new API to get customers and mnos
      const result3 = await this.httpGet('api/user/get-customers-and-mnos');
      if (result3) {
        this.customerIds = result3.data.customerIds;
        this.mnos = result3.data.mnoIds;
      }
    }
  }
}
</script>

