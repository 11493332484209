import axios from '../plugins/axios';
import authService from '@/authService';
import {saveAs} from 'file-saver'

export default {
  async mounted() {
    this.currentUser = JSON.parse(localStorage.user);
  },
  methods: {
    async httpGet(url, headers) {
      try {
        return await axios.get(url, {headers})
      } catch (e) {
        if (e.response.status === 400) {
          alert("input parameter validation failed");
        }
        if (e.response.status === 401 && this.$route.path !== '/login') {
          this.$router.replace({name: 'login'});
          throw e;
        }
        if (e.response.status === 403) {
          authService.logout();
          this.$router.replace({name: 'login'});
          throw e;
        }
        if (e.response.status === 404) {
          alert("Phone number not registered with this user");
        }
        if (e.response.status === 406) {
          alert("User declined");
        }
        if (e.response.status === 408) {
          alert("User ignored");
        }
      }
    },
    async getCustomers() {
      const customers = await this.httpGet('api/customer/getall');
      return customers.data;
    },
    async httpPost(url, data, headers) {
      return await axios.post(url, data, {headers})
        .then(() => console.log("post request passed for data " + data.toString()))
        .catch(e => {
          this.handleResponseError(e);
        })
    },
    async httpDelete(url, data) {
      console.log(url + data)
      return await axios.delete(url + data)
        .then(() => console.log("deleted request passed for data ${data}"))
        .catch(e => {
          this.handleResponseError(e);
        })
    },
    handleResponseError(error) {
      if (error.response.status === 400 || error.response.status === 422) {
        if (error.response.headers['x-error-message'])
          alert(error.response.headers['x-error-message']);
        else
          alert("input parameter validation failed");
        throw error;
      } else if (error.response.status === 409) {
        alert("duplicate record not allowed");
        throw error;
      } else if (error.response.status === 401 && this.$route.path !== '/login') {
        this.$router.replace({name: 'login'});
        throw error;
      } else if (error.response.status === 403) {
        authService.logout();
        this.$router.replace({name: 'login'});
        throw error;
      } else {
        throw error;
      }
    },
    hasRole(roles) {
      return this.currentUser !== null &&
        this.currentUser.authorities &&
        this.currentUser.authorities.some(authority => roles.includes(authority));
    },
    doesntHaveRole(roles) {
      return this.currentUser !== null &&
        this.currentUser.authorities &&
        this.currentUser.authorities.every(authority => !roles.includes(authority));
    },
    hasFeature(feature) {
      return this.currentUser !== null && this.currentUser.features && this.currentUser.features.includes(feature);
    },
    log(message) {
      console.log(message);
    },
    getGitHash() {
      return process.env.VUE_APP_GIT_HASH;
    },
    csvGeneric(fileNamePrefix, blobHeaders, processItem) {
      let blobData = blobHeaders;
      this.results.forEach(item => {
        blobData += processItem(item);
      })
      const currentDate = this.$date(new Date(), 'yyyy-MM-dd_HH:mm:ss')
      saveAs(new Blob([blobData], {type: "text/csv;charset=utf-8"}), `${fileNamePrefix}${currentDate}.csv`)
    },
  },
  data() {
    return {
      roles: {
        'ROLE_ADMIN': 'Admin',
        'ROLE_CUSTOMER_USER': 'Customer User',
        'ROLE_MNO_USER': 'MNO User',
        'ROLE_READ_ONLY_ADMIN': 'Read only admin',
        'ROLE_CUSTOMER_SUCCESS': 'Customer Success',
      },
      currentUser: null
    }
  },
  head() {
    return {
      title: 'Unibeam'
    }
  }
}
